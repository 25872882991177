import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Section, Container } from "./layoutComponents"
import { ButtonSecondaryLight } from "./buttons"

const Wrapper = styled.div`
  background: url("../../images/cta.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 4em 0;
  color: var(--txt-light);
`

export default function CallToAction() {
  return (
    <Wrapper>
      <Container className="spacing">
        <h2 className="title bold italics">
          book your healing session with us
        </h2>
        <p>
          Enjoy an all-encompassing relaxation experience as we combine our
          skillful massage technique with sound therapy to leave you feeling
          tension-free, relaxed, and revitalized.
        </p>
        <ButtonSecondaryLight to="/book-an-appointment">
          book your healing session
        </ButtonSecondaryLight>
      </Container>
    </Wrapper>
  )
}
